import CryptoJS from 'crypto-js'
// import request from '@/utils/request'
import { Message } from 'element-ui'
import { getSecretKey } from "@/utils/screctKey";
//电话号码脱敏
export function phoneMask(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}
//身份证脱敏
export function idCardMask(idCard) {
  return idCard.replace(
    /(^\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)/,
    '$1********$5$6'
  )
}
//手机号码格式验证
export function isPhone(phone) {
  return /^1[3456789]\d{9}$/.test(phone)
}
//身份证格式验证
export function isIdCard(idCard) {
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
    idCard
  )
}

/**自定义日期格式 */
export function formatDate(now, fmt = 'yyyy-MM-dd') {
  let date = new Date(now)
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
  return fmt
}

/**函数防抖
 * event:debounce(function(){},500)
 */
export function debounce(fn, delay = 500) {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}
/**函数节流
 * event:throttle(function(){},500)
 */
export function throttle(fn, delay = 500) {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    if (timer) {
      return
    }
    timer = setTimeout(function () {
      fn.apply(context, args)
      timer = null
    }, delay)
  }
}

// function fillKey(key) {
//   let keySize = 128
//   const filledKey = Buffer.alloc(keySize / 8)
//   const keys = Buffer.from(key)
//   if (keys.length < filledKey.length) {
//     filledKey.map((b, i) => (filledKey[i] = keys[i]))
//   }
//   return filledKey
// }
/**encrypt加密，decrypt解密 */
export function xinyuntiaoaes() {
  const key = CryptoJS.enc.Hex.parse(getSecretKey())
  const iv = CryptoJS.enc.Hex.parse(getSecretKey())
  /**加密 */
  function encrypt(data) {
    const enc = CryptoJS.AES.encrypt(data, key, {
      iv,
    })
    return enc.toString()
  }
  /**解密 */
  function decrypt(data) {
    const dec = CryptoJS.AES.decrypt(data, key, {
      iv,
    })
    return CryptoJS.enc.Utf8.stringify(dec)
  }
  return {
    encrypt,
    decrypt,
  }
}

/**加密post */
export function setPostData(data = {}, config) {
  // if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") return data;
  data['url'] = config.url
  if (
    process.env.NODE_ENV == 'development' ||
    process.env.NODE_ENV == 'staging'
  ) {
    console.table(data)
  }
  return {
    arg0: data ? xinyuntiaoaes().encrypt(JSON.stringify(data)) : '',
  }
}
/**加密get */
export function setGetParams(url = '') {
  // if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") return url;
  let params = url.substring(url.indexOf('?') + 1, url.length)
  if (url.indexOf('?') !== -1) {
    url = url.substring(0, url.indexOf('?'))
  }
  let strs = params.split('&')
  let obj = {}
  for (let i in strs) {
    obj[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]) || ''
  }
  obj['url'] = url || ''

  //判断是开发环境和生成环境
  if (process.env.NODE_ENV == 'development') {
    console.table(obj)
  }
  return `${url}?arg0=${encodeURIComponent(xinyuntiaoaes().encrypt(params))}`
}
/**加密formData 文件上传file，如果存在多个文件加
 * validate 不需要加密的参数返回一个Boolean值，
 * 列：
 * requestFormData({
 *  url:"请求链接",
 *   formData:携带参数,必须通过formData携带,
 * validate:过滤条件
 * }).then()
 *
 */
// export function requestFormData(
//   res = {
//     url: '',
//     formData: new FormData(),
//     method: 'post',
//     validate: (key) => {
//       return key == 'file'
//     },
//     isEncrypt,
//   }
// ) {
//   return new Promise(async (resolve, reject) => {
//     let result = Object.fromEntries(res.formData.entries())
//     let str = ''
//     let resFormData = new FormData()
//     for (let [key, value] of Object.entries(result)) {
//       /**不需要加密的参数 */
//       if (res.validate && res.validate(key)) {
//         resFormData.append(key, value)
//         continue
//       }
//       str += `${key}=${value}${key ? '&' : ''}`
//     }
//     if (!(process.env.NODE_ENV == 'developments')) {
//       res['url'] = str
//         ? `${res.url}?arg0=${encodeURIComponent(
//             encodeURIComponent(xinyuntiaoaes().encrypt(str))
//           )}`
//         : res.url
//     }
//     try {
//       let { data: req } = await request({
//         url: res['url'] || '',
//         method: res.method || 'post',
//         data: resFormData,
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//         isNotEncrypt: !res.isEncrypt,
//       })
//       resolve(req)
//     } catch (error) {
//       reject(error)
//     }
//   })
// }

/**解密 */
export function getDataDecrypt(res = null) {
  let { config, data } = res
  // if (process.env.NODE_ENV == "development"  || process.env.NODE_ENV == "staging") return data;
  if (data == null) return data
  if (config == null) return config
  if (
    config.url.includes('/code') ||
    config['responseType'] == 'blob' ||
    config['responseType'] == 'arraybuffer'
  ) {
    return data
  }
  let req = null
  try {
    req = JSON.parse(xinyuntiaoaes().decrypt(data))
  } catch (error) {
    if (error) {
      Message.error(`${config.url}，当接口返回参数未加密！`)
      return
    }
  }
  return req
}

// 登录接口验签
export function generateSignature(data, timestamp) {
  const secretKey = 't8w6v/fcvIhfI7CQmh/tacuBPQYP2swZbtOgK1+bX8U='
  const signature = generateHmacSHA256Signature(data, secretKey, timestamp)
  console.log('Signature:', signature)
  return signature
}
function generateHmacSHA256Signature(data, secretKey, timestamp) {
  // 按字典序排序参数
  const sortedData = Object.keys(data)
    .sort()
    .reduce((acc, key) => {
      acc[key] = data[key]
      return acc
    }, {})

  sortedData.timestamp = timestamp
  // 将参数序列化为JSON字符串
  const jsonData = JSON.stringify(sortedData)

  // 生成签名
  const signature = CryptoJS.HmacSHA256(jsonData, secretKey)
  return signature.toString(CryptoJS.enc.Hex)
}
