import request from "@/utils/request";

  // 创建会话&获取sessionId
export const getAICreatesessionAPI = (params) =>
  request({
    url: "/service/ai/createsession",
    params,
    method: "get",
  });
// 获取会话状态
export const getAIGetstateAPI = (params) =>
  request({
    url: "/service/ai/getstate",
    params,
    method: "get",
  });
// 开启会话
export const getAIStartsessionAPI = (params) =>
  request({
    url: "/service/ai/startsession",
    params,
    method: "get",
  });
// 开启会话
export const getAIClosesessionAPI = (params) =>
  request({
    url: "/service/ai/closesession",
    params,
    method: "get",
  });
// 获取长链参数
export const getAISignAPI = (params) =>
  request({
    url: "/service/ai/sign",
    params,
    method: "get",
  });

// 开启会话
export const getAIAsrTextAPI = (params) =>
  request({
    url: "/service/ai/asrText",
    params,
    method: "get",
  });
// 获取TRTC参数
export const getTRTCAppidAPI = (params) =>
  request({
    url: "/service/trtc/appid",
    params,
    method: "get",
  });

  // 获取ASR参数
export const getTRTCAsrAPI = (params) =>
  request({
    url: "/service/trtc/asr",
    params,
    method: "get",
  });