import Cookies from "js-cookie";

const SECRETKEY = "secretKey";
export function setSecretKey(setSecretKey) {
  return Cookies.set(SECRETKEY, setSecretKey);
}

export function getSecretKey() {
  try {
    return Cookies.get(SECRETKEY) || "94e874a7d137737ab11e1531d8ab3f6d";
  } catch {
    return "94e874a7d137737ab11e1531d8ab3f6d";
  }
}

export function removeSecretKey() {
  return Cookies.remove(SECRETKEY);
}
