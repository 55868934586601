import request from "@/utils/request";

// 开启云录制
export function startMCUMixTranscodeAPI(params) {
  return request({
    url: "/service/trtc/enable/cloud/cording",
    method: "get",
    params,
  });
}
// 关闭云录制
export function stopMCUMixTranscodeAPI(params) {
  return request({
    url: "/service/trtc/close/cloud/cording",
    method: "get",
    params,
  });
}